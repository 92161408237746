import "./LotCard.css";

import React, { useEffect, useState } from "react";
import { getShipping, getShippingById } from "../../Services/firebase";

const LotCard = (props) => {
  const { data, status } = props;
  const [show, setShow] = useState(false);
  console.log(data, status);
  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="container-card-lot">
      <p>
        <strong>Numero de Lote: </strong> {data.number}
      </p>
      <p>
        <strong>Destino: </strong> {data.destination}
      </p>
      <p>
        <strong>Medio: </strong>
        {data.type == "a" ? "Aereo" : data.type == "m" ? "Maritimo" : ""}
      </p>
      <p>
        <strong>Status: </strong>
        {status == "1"
          ? "Recibido en Almacen"
          : status == "2"
          ? "En transito USA"
          : status == "3"
          ? "En transito Maritimo/Aereo"
          : status == "4"
          ? "En aduana"
          : status == "5"
          ? "En transito a destino"
          : status == "6"
          ? "Entregado"
          : ""}
      </p>
      <div className="shipping-list">
        <p>
          <strong>Envios: </strong>
          {data ? data.shippings.length : ""}
        </p>
        <p className="show-shipping-list" onClick={handleShow}>
          {show ? "Ocultar" : "Ver"}
        </p>
      </div>
      {show && (
        <ul>
          {data.shippings.map((shipping, index) => (
            <li
              style={{
                listStyle: "none",
              }}
              key={index}
            >
              Orden N° {shipping}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LotCard;
