import {
  FaClock,
  FaCubes,
  FaFacebookF,
  FaInstagram,
  FaMailBulk,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaRulerCombined,
  FaShip,
  FaStar,
  FaUsers,
  FaWhatsapp,
} from "react-icons/fa";
import React, { useEffect, useState } from "react";

import Input from "../../Components/Input/Input";
import Tracking from "../../Components/Tracking/Tracking";
import { getShipping } from "../../Services/firebase";
import logo from "../../assets/images/1.png";
import logoWhite from "../Home/img/logo-white.png";
import search from "../../assets/images/icons8-search-64.png";

const SearchScreen = () => {
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [statusData, setStatusData] = useState([]);

  const dataToRender = [
    {
      step: 1,
    },
    {
      step: 2,
    },
    {
      step: 3,
    },
    {
      step: 4,
    },
    {
      step: 5,
    },
    {
      step: 6,
    },
  ];

  const handleValue = () => {
    setShow(false);
    statusData.splice(0, statusData.length);
    const value = document.getElementById("inputSearch");
    getData(value.value);
  };

  const getData = (number) => {
    Promise.all([getShipping()]).then((response) => {
      if (response) {
        response[0].forEach((item) => {
          if (item.data.number == number) {
            setData(item);
            renderStatus(item.data);
          }
        });
      }
    });
  };

  const renderStatus = (data) => {
    console.log("entre");
    if (dataToRender) {
      console.log(dataToRender);
      dataToRender.forEach((item) => {
        let step = parseInt(data.status);
        if (item.step <= step && statusData.length < 6) {
          console.log(item);
          statusData.push({
            step: item.step,
            status: "ready",
          });
          setShow(true);
        } else if (item.step > step && statusData.length < 6) {
          setShow(true);
          statusData.push({
            step: item.step,
            status: "pending",
          });
        }
      });
    }
  };

  useEffect(() => {
    console.log(statusData);
  }, [data, statusData]);

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-primary sticky-top p-0">
        <a
          href="index.html"
          className="navbar-brand bg-primary d-flex align-items-center px-4 px-lg-5"
        >
          <img style={{ width: "200px" }} src={logoWhite} alt="" />
        </a>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <a href="/" className="nav-item nav-link active">
              Home
            </a>
            <a href="/#about" className="nav-item nav-link">
              Nosotros
            </a>
            <a href="/#services" className="nav-item nav-link">
              Servicios
            </a>
            <a href="/#price" className="nav-item nav-link">
              Precios
            </a>
            <a href="#" className="nav-item nav-link">
              Tracking
            </a>
            <a href="#" className="nav-item nav-link">
              Contactanos
            </a>
          </div>
          <h4 className="m-0 pe-lg-5 d-none d-lg-block">
            <a target="_blank" href="https://wa.me/18014996174">
              <FaWhatsapp />
              +1 (801) 499-6174
            </a>
          </h4>
        </div>
      </nav>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="" style={{ width: "300px", margin: "30px" }} />
        <div
          style={{ width: "350px", display: "flex", justifyContent: "center" }}
        >
          <input
            style={{
              width: "350px",
              borderRadius: "15px",
              marginRight: "10px",
            }}
            placeholder="  Ingresa el número de envío"
            id="inputSearch"
          />
          <button style={{ borderRadius: "10px" }} onClick={handleValue}>
            <img style={{ width: "40px" }} src={search} alt="" />
          </button>
        </div>
      </div>
      {show && <Tracking data={statusData} shiping={data}></Tracking>}
      <div
        class="container-fluid bg-dark text-light footer pt-5 wow fadeIn"
        data-wow-delay="0.1s"
        style={{ marginTop: "6rem" }}
      >
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Ubicacion</h4>
              <p class="mb-2">
                <FaMapMarkerAlt />
                175 W. 2700 S. south salt lake, Ut, 84115
              </p>
              <p class="mb-2">
                <FaWhatsapp />
                +1 (801) 499-6174
              </p>
              <p class="mb-2">
                <FaMailBulk />
                info@globalcargous.com
              </p>
              <div class="d-flex pt-2">
                <a
                  target="_blank"
                  class="btn btn-outline-light btn-social"
                  href="https://www.instagram.com/globalcargoutah/"
                >
                  <FaInstagram />
                </a>
                <a
                  target="_blank"
                  class="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/profile.php?id=100063061069991&paipv=0&eav=AfaLowZmlH1SOQacLWMKUd2xAjBFmUhH2HAw6KHQGa1MhQSabe1JfZEXCNFks97YLmE"
                >
                  <FaFacebookF />
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Servicios</h4>
              <div>Deposito</div>

              <div>Envios Maritimos</div>

              <div>Envios Aereos</div>

              <div>Envios terrestres</div>

              <div>Nacionalizacion</div>
            </div>
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Secciones</h4>
              <a class="btn btn-link" href="/#about">
                Nosotros
              </a>
              <a class="btn btn-link" href="/#services">
                Servicios
              </a>
              <a class="btn btn-link" href="/#price">
                Precios
              </a>
            </div>
            <div class="col-lg-3 col-md-6">
              <img style={{ width: "300px" }} src={logoWhite} alt="" />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="copyright">
            <div class="row">
              <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy;{" "}
                <a class="border-bottom" href="#">
                  Global Cargo Utah
                </a>
                , All Right Reserved.
              </div>
              <div class="col-md-6 text-center text-md-end">
                Designed By{" "}
                <a
                  target="_blank"
                  class="border-bottom"
                  href="https://www.linkedin.com/in/gian-machella-1b9a90187/"
                >
                  Gian Machella
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchScreen;
