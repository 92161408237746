import "./Login.css";

import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import React from "react";
import { useUser } from "../../Context/UserContext";
import { userLogin } from "../../Services/firebase";
import wellcome from "../../assets/images/logo-animated.gif";

const Login = () => {
  const { validateLogin } = useUser();
  const [showWellcome, setShowWellcome] = React.useState(false);
  const [hideLogin, setHideLogin] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");

  const login = () => {
    userLogin(email, pass).then((response) => {
      if (response) {
        setShowWellcome(true);
        setHideLogin(true);
        setTimeout(() => {
          window.location.href = "/system";
          validateLogin(response);
          localStorage.setItem("isLogged", true);
          setShowWellcome(false);
        }, 4000);
      }
    });
  };

  return (
    <>
      {showWellcome || hideLogin ? (
        <div className="animation-container">
          <img src={wellcome} />
        </div>
      ) : (
        <div className="container-login">
          <div className="form-login">
            <img src={wellcome} alt="" className="logo-login" />
            <h5>Login</h5>
            <Input label="Email" value={email} onChange={(e) => setEmail(e)} />
            <Input
              type="password"
              label="Password"
              value={pass}
              onChange={(e) => setPass(e)}
            />
            <Button label="Iniciar" onClick={login} />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
