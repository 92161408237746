import "./Select.css";

import React from "react";
import Select from "react-select";
import { placeholder } from "@babel/types";

const InputSelect = (props) => {
  const { options, placeholder, onChange, label, value, id } = props;

  return (
    <div className="selectContainer">
      <label className="label">{label}</label>
      <Select
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        value={value}
      />
    </div>
  );
};

export default InputSelect;
