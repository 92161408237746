import "./Button.css";

import React from "react";

const Button = (props) => {
  const { label, onClick, disabled } = props;
  return (
    <button onClick={onClick} disabled={disabled}>
      <span>{label}</span>
    </button>
  );
};

export default Button;
