import "./BoxContainer.css";

import React from "react";

const BoxContainer = (props) => {
  const { label, children } = props;

  return (
    <div className="boxData-Section">
      <label className="labelBox">{label}</label>
      {children}
    </div>
  );
};

export default BoxContainer;
