import React, { useEffect, useState } from "react";
import {
  addLot,
  getLots,
  getShipping,
  updateLotStatus,
  updateShipping,
} from "../../Services/firebase";

import Alert from "../../Components/Alert/Alert";
import Button from "../../Components/Button/Button";
import LotCard from "../../Components/LotCard/LotCard";
import SystemLayout from "../Layout/SystemLayout";
import logo from "../../assets/images/1.png";

const LotUpdate = () => {
  const [data, setData] = useState([]);
  const [shippingList, setShippingList] = useState([]);
  const [lotData, setLotData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState(null);
  const [lotsOptions, setLotsOptions] = useState([]);

  const styleInput = {
    width: "250px",
    borderRadius: "15px",
    margin: "20px",
  };

  const resetForm = () => {
    document.getElementById("lotSelected").value = null;
    setShippingList([]);
  };

  const formatLostOptions = (data) => {
    let options = [];
    if (data) {
      data.forEach((lot) => {
        options.push({
          value: lot.data.number,
          label: lot.data.number,
          date: lot.data.dateLog
            ? new Date(lot.data.dateLog ? "2/27/2024" : "2/27/2024")
            : new Date("1/1/2000"),
        });
      });
    }

    options.sort((a, b) => {
      return b.date - a.date;
    });
    console.log(options);
    return options;
  };

  const handleUpdate = () => {
    getShipping().then((response) => {
      if (response) {
        response.forEach((shipping) => {
          shippingList.forEach((item) => {
            if (shipping.data.number === item) {
              updateShipping(shipping.id, {
                date: new Date(),
                destination: shipping.data.destination
                  ? shipping.data.destination
                  : shipping.data.address,
                lot: shipping.data.lot,
                number: shipping.data.number,
                sendBy: shipping.data.sendBy,
                sendTo: shipping.data.sendTo,
                type: shipping.data.type ? shipping.data.type : "",
                email: shipping.data.email,
                address: shipping.data.address,
                phone: shipping.data.phone,
                boxList: shipping.data.boxList,
                status: document.getElementById("status").value,
              });
              resetForm();
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
            }
          });
        });
      }
    });
  };

  const handleValue = () => {
    const value = document.getElementById("lotSelected").value;
    if (data) {
      data.forEach((lot) => {
        if (lot.data.number === value) {
          setShippingList(lot.data.shippings);
          setLotData(lot.data);
          getShipping().then((response) => {
            response.forEach((shipping) => {
              if (shippingList[0] === shipping.data.number) {
                console.log(shipping.data.status);
                setStatus(shipping.data.status);
              }
            });
          });
        }
      });
    }
  };

  const getData = () => {
    Promise.all([getLots()]).then((response) => {
      setData(response[0]);
      setLotsOptions(formatLostOptions(response[0]));
    });
  };

  const goBack = () => {
    window.location.href = "/system";
  };

  useEffect(() => {
    getData();
  }, [shippingList]);

  return (
    <SystemLayout pageTitle="Actualizar Lote" link="/system">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button label={"Volver al menu"} onClick={goBack}></Button>
        {showAlert && <Alert text={"Se actualizo el lote con éxito"} />}
        <div
          style={{ width: "350px", display: "flex", justifyContent: "center" }}
        >
          <select
            onChange={handleValue}
            style={styleInput}
            id="lotSelected"
            type="text"
          >
            <option value="" disabled selected>
              Selecciona un lote
            </option>
            {data && (
              <>
                {lotsOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </>
            )}
          </select>
        </div>
        {shippingList.length && (
          <>
            <LotCard data={lotData} status={status} />
            <select style={styleInput} id="status" type="text">
              <option value="" disabled selected>
                Selecciona un estatus
              </option>
              <option value="1"> Recibido en Almacen</option>
              <option value="2"> En transito USA</option>
              <option value="3"> En Transito Maritimo/Aereo</option>
              <option value="4"> En Aduana</option>
              <option value="5"> En transito a destino</option>
              <option value="6"> Entregado</option>
            </select>
            <Button label={"Actualizar"} onClick={handleUpdate}></Button>
          </>
        )}
      </div>
    </SystemLayout>
  );
};

export default LotUpdate;
