import "./CustumerRegister.css";

import React, { useEffect, useState } from "react";
import { addCostumer, getCostumers } from "../../Services/firebase";
import {
  getAuthToken,
  getCities,
  getCountries,
  getStates,
} from "../../Services/ServicesFetch";

import Button from "../../Components/Button/Button";
import CardInfo from "../../Components/CardInfo/CardInfo";
import Input from "../../Components/Input/Input";
import InputSelect from "../../Components/Select/Select";
import Loading from "../../Components/Loading/Loading";
import Swal from "sweetalert2";
import SystemLayout from "../Layout/SystemLayout";

const CustumerRegister = () => {
  const [token, setToken] = useState(null);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [costumers, setCostumers] = useState([]);
  const [costumersFilter, setCostumersFilter] = useState([]);
  const [phoneRecive, setPhoneRecive] = useState("");
  const [nameRecibe, setNameRecibe] = useState("");
  const [lastNameRecibe, setLastNameRecibe] = useState("");

  const formatCountryOptions = (countries) => {
    const options = countries.map((country) => {
      return {
        value: country.country_name,
        label: country.country_name,
      };
    });
    return options;
  };

  const formatStateOptions = (states) => {
    const options = states.map((state) => {
      return {
        value: state.state_name,
        label: state.state_name,
      };
    });
    return options;
  };

  const formatCitiesOptions = (cities) => {
    const options = cities.map((city) => {
      return {
        value: city.city_name,
        label: city.city_name,
      };
    });
    return options;
  };

  const handleChangeCoutry = (e) => {
    setCountry(e);
    getStates(token, e.value).then((response) => {
      setStateOptions(formatStateOptions(response));
    });
  };

  const handleChangeState = (e) => {
    setState(e);
    getCities(token, e.value).then((response) => {
      setCityOptions(formatCitiesOptions(response));
    });
  };

  const handleChangeCity = (e) => {
    setCity(e);
  };

  const handleInputSearch = (e) => {
    setSearchInput(e);
    let results = [];
    if (e.length > 3) {
      results = costumers.filter((costumer) =>
        costumer.name.toLowerCase().includes(e.toLowerCase())
      );
    }

    setCostumersFilter(results);
  };

  const handleSubmit = (e) => {
    let data = {
      name: name,
      lastName: lastName,
      phone: phone,
      email: email,
      recipients: [
        {
          name: nameRecibe,
          lastName: lastNameRecibe,
          phone: phoneRecive,
          address: address,
          country: country,
          state: state,
          city: city,
        },
      ],
    };
    addCostumer(data).then((response) => {
      Swal.fire({
        title: "Cliente agregado correctamente",
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        window.location.href = "/system";
      });
    });
  };

  const handleClick = (e) => {
    let costumerSelected = costumersFilter.find(
      (costumer) => costumer.phone === e.phone
    );
    console.log(costumerSelected);
    setName(costumerSelected.name);
    setLastName(costumerSelected.lastName);
    setPhone(costumerSelected.phone);
    setEmail(costumerSelected.email);
    setNameRecibe(costumerSelected.recipients[0].name);
    setLastNameRecibe(costumerSelected.recipients[0].lastName);
    setPhoneRecive(costumerSelected.recipients[0].phone);
    setAddress(costumerSelected.recipients[0].address);
    setCountry(costumerSelected.recipients[0].country);
    setState(costumerSelected.recipients[0].state);
    setCity(costumerSelected.recipients[0].city);

    setCostumersFilter([costumerSelected]);
  };

  const goBack = () => {
    window.location.href = "/system";
  };

  useEffect(() => {
    setIsLoading(true);
    getCostumers().then((response) => {
      setCostumers(response);
      setIsLoading(false);
    });
    getAuthToken().then((response) => {
      setToken(response.auth_token);
      getCountries(response.auth_token).then((response) => {
        setCountriesOptions(formatCountryOptions(response));
      });
    });
  }, []);
  return (
    <>
      {isLoading ? (
        <Loading className="align-center" />
      ) : (
        <SystemLayout>
          <div className="goBack">
            <Button label={"Volver al menu"} onClick={goBack}></Button>
          </div>
          <div className="searchBox">
            <Input label="Buscar" onChange={handleInputSearch} />
          </div>
          <div className="searchResults row">
            {searchInput && searchInput.length > 3 && (
              <>
                {costumersFilter.map((costumer, index) => (
                  <CardInfo
                    key={index}
                    onClick={() => handleClick(costumer)}
                    data={costumer}
                  />
                ))}
              </>
            )}
          </div>
          <div className="container-form row">
            <div className="send-data col-md-4">
              <label className="label">Datos del que envia:</label>
              <Input
                label="Nombre"
                value={name ? name : ""}
                onChange={(e) => setName(e)}
              />
              <Input
                value={lastName ? lastName : ""}
                onChange={(e) => setLastName(e)}
                label="Apellido"
              />
              <Input
                value={phone ? phone : ""}
                onChange={(e) => setPhone(e)}
                label="Telefono"
              />
              <Input
                value={email ? email : ""}
                onChange={(e) => setEmail(e)}
                label="Email"
              />
            </div>
            <div className="recive-data col-md-4">
              <label className="label">Datos del que recive:</label>
              <Input
                value={nameRecibe ? nameRecibe : ""}
                onChange={(e) => setNameRecibe(e)}
                label="Nombre"
              />
              <Input
                value={lastNameRecibe ? lastNameRecibe : ""}
                onChange={(e) => setLastNameRecibe(e)}
                label="Apellido"
              />
              <Input
                value={phoneRecive ? phoneRecive : ""}
                onChange={(e) => setPhoneRecive(e)}
                label="Telefono"
              />
            </div>
            <div className="address col-md-4">
              <InputSelect
                value={country ? country : null}
                options={countriesOptions}
                onChange={handleChangeCoutry}
                label="Pais"
              />
              <InputSelect
                value={state ? state : null}
                onChange={handleChangeState}
                options={stateOptions}
                label="Estado/Departamento"
              />
              <InputSelect
                value={city ? city : null}
                options={cityOptions}
                onChange={handleChangeCity}
                label="Ciudad"
              />
              <Input
                value={address ? address : ""}
                onChange={(e) => setAddress(e)}
                size="mediun"
                label="Direccion"
              />
            </div>
          </div>
          <div className="goBack">
            <Button onClick={handleSubmit} label="Guardar"></Button>
          </div>
        </SystemLayout>
      )}
    </>
  );
};

export default CustumerRegister;
