import "./LotMenu.css";

import Button from "../../Components/Button/Button";
import ButtonMenu from "../../Components/ButtonMenu/ButtonMenu";
import React from "react";
import SystemLayout from "../Layout/SystemLayout";

export const LotMenu = () => {
  const goBack = () => {
    window.location.href = "/system";
  };

  return (
    <div>
      <SystemLayout pageTitle="Envios">
        <div className="goBack">
          <Button label={"Volver al menu"} onClick={goBack}></Button>
        </div>
        <div className="container-menu">
          <ButtonMenu
            label="Registrar Lote"
            icon="shipping"
            link="/system/lot-form"
          />
          <ButtonMenu
            label="Ver/Actualizar Lote"
            icon="shipping"
            link="/system/lot-update/"
          />
        </div>
      </SystemLayout>
    </div>
  );
};

export default LotMenu;
