import "./ShipingForm.css";

import React, { useEffect, useMemo, useState } from "react";
import {
  addCostumer,
  addShipping,
  getCostumers,
  getLots,
  getShippingById,
  sendEmail,
  updateLots,
  updateShipping,
} from "../../Services/firebase";

import Alert from "../../Components/Alert/Alert";
import BoxContainer from "../../Components/BoxContainer/BoxContainer";
import Button from "../../Components/Button/Button";
import CardInfo from "../../Components/CardInfo/CardInfo";
import Input from "../../Components/Input/Input";
import InputSelect from "../../Components/Select/Select";
import Swal from "sweetalert2";
import SystemLayout from "../Layout/SystemLayout";
import boxImg from "../../assets/images/box.png";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";
import { v4 as uuid } from "uuid";

const ShipingForm = (props) => {
  const { editData } = props;

  const [dataEditForm, setDataEditForm] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [lotNumber, setLotNumber] = useState("");
  const [shipingNumber, setShipingNumber] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nameRecibe, setNameRecibe] = useState("");
  const [lastNameRecibe, setLastNameRecibe] = useState("");
  const [phoneRecibe, setPhoneRecibe] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [costumers, setCostumers] = useState([]);
  const [costumersFilter, setCostumersFilter] = useState([]);

  const [lotsList, setLotsList] = useState([]);
  const [lotsOptions, setLotsOptions] = useState([]);
  const [lot, setLot] = useState(null);
  const [boxSize, setBoxSize] = useState("");
  const [lotSelected, setLotSelected] = useState(false);
  const [sizeBoxSelected, setSizeBoxSelected] = useState(false);
  const [personalizada, setPersonalizada] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [boxsList, setBoxsList] = useState([]);
  const [sizeBox, setSizeBox] = useState("");
  const [showCustomSize, setShowCustomSize] = useState(false);
  const [boxWeight, setBoxWeight] = useState("");
  const [boxSizePersonality, setBoxSizePersonality] = useState(null);

  const sizeBoxOptions = [
    { value: "17X11X11 - Small", label: "17X11X11 - Small" },
    { value: "12X12X16 - Small", label: "12X12X16 - Small" },
    { value: "21X15X16 - Medium", label: "21X15X15 - Medium" },
    { value: "18X18X16 - Medium", label: "18X18X16 - Medium" },
    { value: "27X15X16 - Large", label: "27X15X16 - Large" },
    { value: "18X18X24 - Large", label: "18X18X24 - Large" },
    { value: "24X20X21 - ExtraLarge", label: "24X20X21 - ExtraLarge" },
    { value: "22X22X21 - ExtraLarge", label: "22X22X21 - ExtraLarge" },
    { value: "personality", label: "Tamaño personalizado" },
  ];

  const resetData = () => {
    setLotNumber("");
    setShipingNumber("");
    setName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setNameRecibe("");
    setLastNameRecibe("");
    setPhoneRecibe("");
    setAddress("");
    setStatus("");
    setBoxsList([]);
  };

  const formatLostOptions = (data) => {
    let options = [];
    data.forEach((lot) => {
      options.push({
        value: lot.data.number,
        label: lot.data.number,
        date: lot.data.dateLog
          ? new Date(lot.data.dateLog)
          : new Date("1/1/2000"),
      });
    });

    options.sort((a, b) => {
      return b.date - a.date;
    });

    console.log("Opciones ordenadas",options);
    return options;
  };

  const getLotsData = () => {
    Promise.all([getLots()]).then((response) => {
      setLotsOptions(formatLostOptions(response[0]));
      setLotsList(response[0]);
    });
  };

  const getLotData = () => {
    lotsList.forEach((lotData) => {
      if (lotData.data.number === lotNumber.value) {
        setLot(lotData);
      }
    });
  };

  const addShippingToLot = (data, shippingNumber) => {
    let lotData = data;
    lotData.data.shippings.push(shippingNumber);
    setLot(lotData);
  };

  const handleSave = () => {
    Swal.fire({
      title: "Quieres registar el cliente y el envio, o solo el envio?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Guardar Cliente y Envio",
      denyButtonText: `Guardar solo Envio`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleSubmitCostumer();
      } else if (result.isDenied) {
        handleSubmit();
      }
    });
  };

  const handleSubmit = () => {
    let dataShipping = {
      lotNumber: lotNumber.value,
      shipingNumber: shipingNumber,
      name: name,
      lastName: lastName,
      email: email,
      phone: phone,
      nameRecibe: nameRecibe,
      lastNameRecibe: lastNameRecibe,
      phoneRecibe: phoneRecibe,
      address: address,
      status: status,
      boxList: boxsList,
      aduana: false,
      date: new Date(),
    };

    addShippingToLot(lot, shipingNumber);

    addShipping(dataShipping).then((response) => {
      console.log(lot);
      if (response) {
        updateLots(lot.id, lot.data);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Envio registrado",
            text: "El envio se registro correctamente",
          }).then((res) => {
            if (res) {
              setEmailToSend();
              window.location.reload(true);
            }
          });
        }
      }
    });
  };

  const handleSubmitCostumer = () => {
    let dataShipping = {
      lotNumber: lotNumber.value,
      shipingNumber: shipingNumber,
      name: name,
      lastName: lastName,
      email: email,
      phone: phone,
      nameRecibe: nameRecibe,
      lastNameRecibe: lastNameRecibe,
      phoneRecibe: phoneRecibe,
      address: address,
      status: status,
      boxList: boxsList,
      aduana: false,
      date: new Date(),
    };

    let dataCostumer = {
      name: name,
      lastName: lastName,
      email: email,
      phone: phone,
      recipients: [
        {
          name: nameRecibe,
          lastName: lastNameRecibe,
          phone: phoneRecibe,
          address: address,
        },
      ],
      date: new Date(),
    };

    addShippingToLot(lot, shipingNumber);

    addCostumer(dataCostumer).then((response) => {
      console.log(dataCostumer);
      if (response) {
        addShipping(dataShipping).then((response) => {
          console.log(lot);
          if (response) {
            updateLots(lot.id, lot.data);
            if (response) {
              Swal.fire({
                icon: "success",
                title: "Envio y Cliente registrado",
                text: "El envio y el cliente se registraron correctamente",
              }).then((res) => {
                if (res) {
                  setEmailToSend();
                }
              });
            }
          }
        });
      }
    });
  };

  const formatDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return day + "/" + month + "/" + year;
  };

  const setEmailToSend = () => {
    sendEmail({
      to: email,
      destination: lot.data.destination,
      subject: "Envío registrado",
      address: address,
      nameSend: name,
      shippingNumber: shipingNumber,
      nameRecive: nameRecibe,
      phone: phone,
      email: email,
      boxsList: boxsList,
      date: formatDate(new Date()),
      type: lot.data.type,
    }).then((respose) => {
      //window.location.reload(true);
    });
  };

  const handleInputSearch = (e) => {
    setSearchInput(e);
    let results = [];
    if (e.length > 3) {
      results = costumers.filter((costumer) =>
        costumer.name.toLowerCase().includes(e.toLowerCase())
      );
    }

    setCostumersFilter(results);
  };

  const handleClick = (e) => {
    let costumerSelected = costumersFilter.find(
      (costumer) => costumer.phone === e.phone
    );
    setCostumersFilter([costumerSelected]);
    setName(costumerSelected.name);
    setLastName(costumerSelected.lastName);
    setEmail(costumerSelected.email);
    setPhone(costumerSelected.phone);

    setNameRecibe(costumerSelected.recipients[0].name);
    setLastNameRecibe(costumerSelected.recipients[0].lastName);
    setPhoneRecibe(costumerSelected.recipients[0].phone);
    setAddress(costumerSelected.recipients[0].address);
  };

  const deleteBox = (id) => {
    boxsList.forEach((box) => {
      if (box.id === id) {
        boxsList.splice(boxsList.indexOf(box), 1);
        setBoxsList([...boxsList]);
      }
    });
  };

  const handleChangeLote = (e) => {
    setLotNumber(e);
  };

  const handleChangeSize = (e) => {
    setBoxSize(e.value);
    if (e.value === "personality") {
      setPersonalizada(true);
      setShowCustomSize("personality");
    } else {
      setPersonalizada(false);
      setShowCustomSize(null);
    }
  };

  const handleBoxList = () => {
    let boxListInt = boxsList;
    let id = uuid();
    if (boxSize === "personality") {
      boxListInt.push({
        boxSize: boxSizePersonality,
        boxWeight: boxWeight,
        id: id,
      });
    } else {
      boxListInt.push({
        boxSize: boxSize,
        boxWeight: boxWeight,
        id: id,
      });
    }
    setBoxSize(null);
    setBoxsList([...boxListInt]);
  };

  const goBack = () => {
    window.location.href = "/system";
  };

  const goBackMenu = () => {
    window.location.href = "/system/shipping";
  };

  useMemo(() => {
    getCostumers().then((response) => {
      setCostumers(response);
    });
    getLotsData();
    if (editData) {
    }
  }, [lotSelected]);

  useEffect(() => {
    getLotData();
  }, [
    sizeBoxSelected,
    boxSize,
    personalizada,
    lotsList,
    lot,
    lotNumber,
    shipingNumber,
    name,
    lastName,
    nameRecibe,
    lastNameRecibe,
    phoneRecibe,
    address,
    email,
    phone,
    dataEditForm,
    status,
    boxSize,
  ]);

  return (
    <>
      <SystemLayout pageTitle="Formulario de envio">
        <div className="container">
          <div className="goBack">
            <Button label={"Volver al menu"} onClick={goBack}></Button>
          </div>
          <div className="searchBox">
            <Input label="Buscar Cliente" onChange={handleInputSearch} />
          </div>
          <div className="searchResults row">
            {searchInput && searchInput.length > 3 && (
              <>
                {costumersFilter.map((costumer, index) => (
                  <CardInfo
                    key={index}
                    onClick={() => handleClick(costumer)}
                    data={costumer}
                  />
                ))}
              </>
            )}
          </div>
          <div className=" row form">
            <div className="col-md-6">
              <BoxContainer label="Datos de quien envia">
                <Input
                  onChange={(e) => setName(e)}
                  value={name}
                  type="text"
                  label="Nombre"
                ></Input>
                <Input
                  onChange={(e) => setLastName(e)}
                  value={lastName}
                  type="text"
                  label="Apellido"
                ></Input>
                <Input
                  onChange={(e) => setEmail(e)}
                  value={email}
                  type="text"
                  label="Email de quien envia"
                ></Input>
                <Input
                  onChange={(e) => setPhone(e)}
                  value={phone}
                  type="text"
                  label="Telefono de quien envia"
                ></Input>
              </BoxContainer>
            </div>
            <div className="col-md-6">
              <BoxContainer label="Datos de quien recibe">
                <Input
                  onChange={(e) => setNameRecibe(e)}
                  value={nameRecibe}
                  type="text"
                  label="Nombre"
                ></Input>
                <Input
                  onChange={(e) => setLastNameRecibe(e)}
                  value={lastNameRecibe}
                  type="text"
                  label="Apellido"
                ></Input>
                <Input
                  onChange={(e) => setPhoneRecibe(e)}
                  value={phoneRecibe}
                  type="text"
                  label="Telefono"
                ></Input>
                <Input
                  onChange={(e) => setAddress(e)}
                  value={address}
                  type="text"
                  size="large"
                  label="Direccion de quien recibe"
                ></Input>
              </BoxContainer>
            </div>
            <div className="col-md-6">
              <BoxContainer label="Identificador de la caja">
                <InputSelect
                  label="Lote"
                  options={lotsOptions}
                  placeholder="Selecciona un lote"
                  onChange={handleChangeLote}
                ></InputSelect>
                <Input
                  onChange={(e) => setShipingNumber(e)}
                  type="text"
                  label="Numero de envio"
                ></Input>
              </BoxContainer>
            </div>
            <div className="col-md-6">
              <BoxContainer label="Descripcion de las cajas">
                <div className="boxSize">
                  <InputSelect
                    id="sizeBox"
                    label="Tamaño de caja"
                    options={sizeBoxOptions}
                    placeholder="Selecciona..."
                    onChange={handleChangeSize}
                  ></InputSelect>
                  <Button
                    onClick={handleBoxList}
                    className="buttom"
                    label="Agregar Caja"
                  />
                </div>
                <Input
                  type="text"
                  onChange={(e) => setBoxWeight(e)}
                  label="Peso"
                ></Input>

                {showCustomSize === "personality" && (
                  <Input
                    onChange={(e) => setBoxSizePersonality(e)}
                    type="text"
                    label="Medidas Personalizada"
                  ></Input>
                )}
                {boxsList && (
                  <>
                    <h6>Lista de cajas Cargadas:</h6>
                    {boxsList.map((box, index) => (
                      <div className="boxLitsItem" key={index}>
                        <div className="box">
                          <img width="20" src={boxImg} alt="" />
                        </div>
                        <p>{box.boxSize}</p>
                        {box.boxWeight && <p>{box.boxWeight + " lbs"}</p>}
                        <button
                          onClick={() => deleteBox(box.id)}
                          className="deleteBox"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </>
                )}
              </BoxContainer>
            </div>
          </div>
          <div className="saveButton">
            <Button onClick={handleSave} label="Guardar" />
          </div>
        </div>
      </SystemLayout>
    </>
  );
};

export default ShipingForm;
