import "./Input.css";

import React, { useEffect } from "react";

const Input = (props) => {
  const { label, type, size, onChange, value } = props;

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="input-field">
      <input
        onChange={handleChange}
        type={type}
        className={size}
        required
        value={value}
      />
      <label>{label}</label>
    </div>
  );
};

export default Input;
