import React, { useEffect, useState } from "react";

import aduane from "../../assets/images/AduaneGC.png";
import aduaneW from "../../assets/images/AduaneW.png";
import done from "../../assets/images/doneGC.png";
import doneW from "../../assets/images/DoneW.png";
import plane from "../../assets/images/PlaneGC.png";
import planeW from "../../assets/images/PlaneW.png";
import ship from "../../assets/images/ShipGC.png";
import shipW from "../../assets/images/ShipW.png";
import store from "../../assets/images/StoreGC.png";
import storeW from "../../assets/images/StoreW.png";
import truck from "../../assets/images/truckGC.png";
import truckW from "../../assets/images/TruckW.png";

const Tracking = (props) => {
  const { data, shiping } = props;
  const [status, setStatus] = useState(null);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 500px)").matches
  );

  const ready = "#BE1C2D";
  const pendding = "#ced4da";

  const setStatusShiping = (status) => {
    switch (status) {
      case "1":
        setStatus("recibido en nuestro almacen");
        break;
      case "2":
        setStatus("en transito en USA");
        break;
      case "3":
        if (shiping.data.type === "m") {
          setStatus("en transito marítimo");
        } else {
          setStatus("en transito aéreo");
        }
        break;
      case "4":
        setStatus("en aduana");
        break;
      case "5":
        setStatus("en transito de destino");
        break;
      case "6":
        setStatus("entregado");
        break;
      case "7":
        setStatus("retenido en aduana");
        break;

      default:
        break;
    }
  };

  const setSizeWindow = () => {
    setMatches(window.matchMedia("(min-width: 500px)").matches);
  };

  useEffect(() => {
    setStatusShiping(shiping.data.status);
    setSizeWindow();
    console.log(shiping.data);
  }, [data, shiping, matches]);

  return (
    <>
      <div
        style={
          matches
            ? {
                width: "100%",
                padding: "30px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                mediaQuery: {},
              }
            : {
                width: "100%",
                padding: "30px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                mediaQuery: {},
              }
        }
      >
        {data && shiping.data.status != "7" && (
          <>
            {data.map((step, index) => (
              <div
                key={index}
                style={
                  matches
                    ? {
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                      }
                    : {
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }
                }
              >
                <div
                  style={{
                    backgroundColor: `${
                      step.status === "ready" ? ready : pendding
                    }`,
                    width: "60px",
                    height: "60px",
                    borderRadius: "40px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={
                      step.step === 1 && step.status === "ready"
                        ? storeW
                        : step.step === 1 && step.status === "pending"
                        ? store
                        : step.step === 2 && step.status === "ready"
                        ? truckW
                        : step.step === 2 && step.status === "pending"
                        ? truck
                        : step.step === 3 &&
                          step.status === "ready" &&
                          shiping.data.type === "m"
                        ? shipW
                        : step.step === 3 &&
                          step.status === "pending" &&
                          shiping.data.type === "m"
                        ? ship
                        : step.step === 3 &&
                          step.status === "ready" &&
                          shiping.data.type === "a"
                        ? planeW
                        : step.step === 3 &&
                          step.status === "pending" &&
                          shiping.data.type === "a"
                        ? plane
                        : step.step === 4 && step.status === "ready"
                        ? aduaneW
                        : step.step === 4 && step.status === "pending"
                        ? aduane
                        : step.step === 5 && step.status === "ready"
                        ? truckW
                        : step.step === 5 && step.status === "pending"
                        ? truck
                        : step.step === 6 && step.status === "ready"
                        ? doneW
                        : step.step === 6 && step.status === "pending"
                        ? done
                        : store
                    }
                    alt=""
                    style={{ width: "60px" }}
                  />
                </div>
                {step.step < 6 && (
                  <div
                    style={
                      matches
                        ? {
                            width: "60px",
                            height: "10px",
                            marginTop: "25px",
                            backgroundColor: `${
                              step.status === "ready" ? ready : pendding
                            }`,
                          }
                        : {
                            width: "10px",
                            height: "60px",
                            backgroundColor: `${
                              step.status === "ready" ? ready : pendding
                            }`,
                          }
                    }
                  />
                )}
              </div>
            ))}
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "40px",
        }}
      >
        {shiping.data.status == "7" && (
          <>
            <h1
              style={{
                marginBottom: "20px",
                marginTop: "-50px",
              }}
            >
              Su envío se encuentra{" "}
              <strong
                style={{
                  color: "#BE1C2D",
                }}
              >
                RETENIDO
              </strong>{" "}
              en Aduana
            </h1>
            <p>Comuníquese con nosotros para obtener más información</p>
          </>
        )}
        {shiping.data.status != "7" && (
          <>
            <h3>Hola, {shiping.data.sendBy}!!</h3>
            <h5>
              Su envío a {shiping.data.sendTo}, <b>se encuenta {status}</b>
            </h5>
          </>
        )}
      </div>
    </>
  );
};

export default Tracking;
