import React from "react";

const Alert = (props) => {
  const { text } = props;
  return (
    <div
      style={{
        backgroundColor: "#80ed99",
        width: "300px",
        height: "30px",
        marginTop: "20px",
        borderRadius: "10px",
        textAlign: "center",
      }}
    >
      <p>{text}</p>
    </div>
  );
};

export default Alert;
