import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import logo from "../assets/images/1.png";

const firebaseConfig = {
  apiKey: "AIzaSyC4Y3twT9a-sLzP6Jf2iY6iIOw_lA4oRT4",
  authDomain: "tracking-app-9768c.firebaseapp.com",
  projectId: "tracking-app-9768c",
  storageBucket: "tracking-app-9768c.appspot.com",
  messagingSenderId: "93656506669",
  appId: "1:93656506669:web:89e84304cf7c08ee7f73a4",
  measurementId: "G-9NSM1HHMF5",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export async function userLogOut() {
  signOut(auth)
    .then(() => {
      console.log("usuario deslogueado");
      localStorage.setItem("isLogged", false);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function userLogin(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential;
  } catch (error) {
    console.log(error);
  }
}

export async function monitoringAuthState() {
  try {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user.auth);
        return user.auth;
      } else {
        console.log(false);
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export async function getLots() {
  let lots = [];
  try {
    const collectionRef = collection(db, "lots");
    const querySnapshot = await getDocs(collectionRef);

    if (querySnapshot) {
      querySnapshot.forEach((lot) => {
        const lotData = { id: lot.id, data: lot.data() };
        lots.push(lotData);
      });
    }
    console.log(lots);
    return lots;
  } catch (error) {
    console.log(error);
  }
}

export async function getShipping() {
  let items = [];
  try {
    const collectionRef = collection(db, "shipping");
    const docSnap = await getDocs(collectionRef);

    if (docSnap) {
      docSnap.forEach((doc) => {
        items.push({ id: doc.id, data: doc.data() });
      });
    }

    return items;
  } catch (error) {
    console.log(error);
  }
}

export async function getCostumers() {
  let items = [];
  try {
    const collectionRef = collection(db, "costumer");
    const docSnap = await getDocs(collectionRef);

    if (docSnap) {
      docSnap.forEach((doc) => {
        items.push({
          id: doc.id,
          name: doc.data().name,
          lastName: doc.data().lastName,
          phone: doc.data().phone,
          email: doc.data().email,
          recipients: doc.data().recipients,
        });
      });
    }

    return items;
  } catch (error) {
    console.log(error);
  }
}

export async function getShippingById(id) {
  let items = [];
  try {
    const collectionRef = collection(db, "shipping");
    const docSnap = await getDocs(collectionRef);

    if (docSnap) {
      docSnap.forEach((doc) => {
        items.push({ id: doc.id, data: doc.data() });
      });
    }
    let item = items.filter((item) => item.data.number === id);
    console.log(item);
    console.log(id);

    return item;
  } catch (error) {
    console.log(error);
  }
}

export async function addShipping(data) {
  console.log(data);
  try {
    const collectionRef = collection(db, "shipping");
    const docSnap = await addDoc(collectionRef, {
      lot: data.lotNumber,
      number: data.shipingNumber,
      sendBy: data.name,
      lastName: data.lastName,
      sendTo: data.nameRecibe,
      lastNameRecibe: data.lastNameRecibe,
      email: data.email,
      address: data.address,
      boxList: data.boxList,
      phone: data.phone,
      phoneRecibe: data.phoneRecibe,
      date: data.date,
      status: "1",
      aduana: data.aduana,
    });
    return {
      message: "save success",
      dataSaved: true,
    };
  } catch (error) {
    console.log(error);
  }
}

export async function addLot(data) {
  try {
    let date = new Date();
    let dateFormate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    const collectionRef = collection(db, "lots");
    const docSnap = await addDoc(collectionRef, {
      destination: data.destination,
      number: data.lotNumber,
      type: data.type,
      dateLog: dateFormate,
      shippings: [],
    });
  } catch (error) {
    console.log(error);
  }
}

export async function addCostumer(data) {
  try {
    const collectionRef = collection(db, "costumer");
    const docSnap = await addDoc(collectionRef, {
      name: data.name,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      recipients: data.recipients,
    });
    return "Saved";
  } catch (error) {
    console.log(error);
  }
}

export async function updateShipping(id, data) {
  try {
    const docRef = doc(db, "shipping", id);
    const res = await setDoc(docRef, data);
    return {
      message: "save success",
      dataSaved: true,
    };
  } catch (error) {
    console.log(error);
  }
}

export async function updateLots(id, data) {
  try {
    const docRef = doc(db, "lots", id);
    const res = await setDoc(docRef, data);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function sendEmail(data) {
  console.log(data);
  const weightUnit = "Lbs";
  try {
    const collectionRef = collection(db, "mail");
    const docSnap = await addDoc(collectionRef, {
      to: data.to,
      message: {
        subject: data.subject,
        html: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG />
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    <![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="x-apple-disable-message-reformatting" />
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <!--<![endif]-->
    <title></title>

    <style type="text/css">
      @media only screen and (min-width: 620px) {
        .u-row {
          width: 600px !important;
        }
        .u-row .u-col {
          vertical-align: top;
        }

        .u-row .u-col-33p33 {
          width: 199.98px !important;
        }

        .u-row .u-col-66p67 {
          width: 400.02px !important;
        }

        .u-row .u-col-100 {
          width: 600px !important;
        }
      }

      @media (max-width: 620px) {
        .u-row-container {
          max-width: 100% !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        .u-row .u-col {
          min-width: 320px !important;
          max-width: 100% !important;
          display: block !important;
        }
        .u-row {
          width: 100% !important;
        }
        .u-col {
          width: 100% !important;
        }
        .u-col > div {
          margin: 0 auto;
        }
      }
      body {
        margin: 0;
        padding: 0;
      }

      table,
      tr,
      td {
        vertical-align: top;
        border-collapse: collapse;
      }

      p {
        margin: 0;
      }

      .ie-container table,
      .mso-container table {
        table-layout: fixed;
      }

      * {
        line-height: inherit;
      }

      a[x-apple-data-detectors="true"] {
        color: inherit !important;
        text-decoration: none !important;
      }

      table,
      td {
        color: #000000;
      }
      #u_body a {
        color: #0000ee;
        text-decoration: underline;
      }
      #u_content_text_2 a {
        color: #95a5a6;
      }
      @media (max-width: 480px) {
        #u_content_image_1 .v-container-padding-padding {
          padding: 20px 10px 0px !important;
        }
        #u_content_image_1 .v-src-width {
          width: auto !important;
        }
        #u_content_image_1 .v-src-max-width {
          max-width: 31% !important;
        }
        #u_content_heading_1 .v-container-padding-padding {
          padding: 10px 10px 5px !important;
        }
        #u_content_heading_1 .v-font-size {
          font-size: 20px !important;
        }
        #u_content_text_5 .v-container-padding-padding {
          padding: 5px 10px 0px !important;
        }
        #u_content_text_5 .v-font-size {
          font-size: 13px !important;
        }
        #u_content_heading_2 .v-container-padding-padding {
          padding: 10px 10px 5px !important;
        }
        #u_content_heading_2 .v-font-size {
          font-size: 20px !important;
        }
        #u_content_text_1 .v-container-padding-padding {
          padding: 5px 10px 0px !important;
        }
        #u_content_text_1 .v-font-size {
          font-size: 13px !important;
        }
        #u_content_text_2 .v-container-padding-padding {
          padding: 40px 10px 10px !important;
        }
      }
    </style>

    <!--[if !mso]><!-->
    <link
      href="https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap"
      rel="stylesheet"
      type="text/css"
    />
    <!--<![endif]-->
  </head>

  <body
    class="clean-body u_body"
    style="
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      background-color: #ffffff;
      color: #000000;
    "
  >
    <table
      id="u_body"
      style="
        border-collapse: collapse;
        table-layout: fixed;
        border-spacing: 0;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        vertical-align: top;
        min-width: 320px;
        margin: 0 auto;
        background-color: #ffffff;
        width: 100%;
      "
      cellpadding="0"
      cellspacing="0"
    >
      <tbody>
        <tr style="vertical-align: top">
          <td
            style="
              word-break: break-word;
              border-collapse: collapse !important;
              vertical-align: top;
            "
          >
            <div
              class="u-row-container"
              style="
                padding: 0px;
                background-image: url('images/image-3.png');
                background-repeat: no-repeat;
                background-position: center top;
                background-color: transparent;
              "
            >
              <div
                class="u-row"
                style="
                  margin: 0 auto;
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  background-color: transparent;
                "
              >
                <div
                  style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                  "
                >
                  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-image: url('images/image-3.png');background-repeat: no-repeat;background-position: center top;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                  <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color: #ffffff;width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-100"
                    style="
                      max-width: 320px;
                      min-width: 600px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        background-color: #ffffff;
                        height: 100%;
                        width: 100% !important;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 0px solid transparent;
                          border-left: 0px solid transparent;
                          border-right: 0px solid transparent;
                          border-bottom: 0px solid transparent;
                        "
                      ><!--<![endif]-->
                        <table
                          id="u_content_image_1"
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 50px 10px 0px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <table
                                  width="100%"
                                  cellpadding="0"
                                  cellspacing="0"
                                  border="0"
                                >
                                  <tr>
                                    <td
                                      style="
                                        padding-right: 0px;
                                        padding-left: 0px;
                                      "
                                      align="center"
                                    >
                                      <img
                                        align="center"
                                        border="0"
                                        src="https://i.imgur.com/acOsFpQ.png"
                                        alt="image"
                                        title="image"
                                        style="
                                          outline: none;
                                          text-decoration: none;
                                          -ms-interpolation-mode: bicubic;
                                          clear: both;
                                          display: inline-block !important;
                                          border: none;
                                          height: auto;
                                          float: none;
                                          width: 35%;
                                          max-width: 203px;
                                        "
                                        width="203"
                                        class="v-src-width v-src-max-width"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          id="u_content_heading_1"
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px 50px 5px;
                                  font-family: 'Raleway', sans-serif;
                                "
                              >
                                <h4
                                  class="v-font-size"
                                  style="
                                    margin: 0px;
                                    color: #a00f00;
                                    line-height: 140%;
                                    text-align: center;
                                    word-wrap: break-word;
                                    font-family: inherit;
                                    font-size: 16px;
                                    font-weight: 400;
                                  "
                                >
                                  <h1
                                    style="
                                      color: #222222;
                                      white-space: normal;
                                      text-align: center;
                                      background-color: #ffffff;
                                    "
                                  >
                                    ¡Hola ${data.nameSend}!
                                  </h1>
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          id="u_content_text_5"
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px 50px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: center;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <span
                                      style="
                                        color: #222222;
                                        white-space: normal;
                                        background-color: #ffffff;
                                        float: none;
                                        display: inline;
                                        line-height: 19.6px;
                                      "
                                      >Tu envío ha sido registrado con el
                                      número:</span
                                    >
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          id="u_content_heading_2"
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px 50px 5px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <h1
                                  class="v-font-size"
                                  style="
                                    margin: 0px;
                                    color: #a00f00;
                                    line-height: 140%;
                                    text-align: center;
                                    word-wrap: break-word;
                                    font-family: inherit;
                                    font-size: 22px;
                                    font-weight: 400;
                                  "
                                >
                                  <h1
                                    style="
                                      color: #222222;
                                      white-space: normal;
                                      background-color: #ffffff;
                                      text-align: center;
                                    "
                                  >
                                    ${data.shippingNumber}
                                  </h1>
                                </h1>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          id="u_content_text_1"
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px 50px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 12px;
                                    line-height: 140%;
                                    text-align: center;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p
                                    style="
                                      color: #222222;
                                      white-space: normal;
                                      background-color: #ffffff;
                                      line-height: 140%;
                                    "
                                  >
                                    Y será enviado a<strong>
                                      ${data.nameRecive}</strong
                                    >
                                    en la dirección:
                                    <strong>${data.address}</strong> en los
                                    próximos días.
                                  </p>
                                  <p
                                    style="
                                      color: #222222;
                                      white-space: normal;
                                      background-color: #ffffff;
                                      line-height: 140%;
                                    "
                                  >
                                     
                                  </p>
                                  <p
                                    style="
                                      color: #222222;
                                      white-space: normal;
                                      background-color: #ffffff;
                                      line-height: 140%;
                                    "
                                  >
                                    Recuerde que el tiempo estimado de llegada
                                    aproximadamente es de
                                    <strong
                                      >${
                                        data.type === "m"
                                          ? "6 a 8 semnanas"
                                          : "2 a 3 semnanas"
                                      }</strong
                                    >.
                                  </p>
                                  <p
                                    style="
                                      color: #222222;
                                      white-space: normal;
                                      background-color: #ffffff;
                                      line-height: 140%;
                                    "
                                  >
                                     
                                  </p>
                                  <p
                                    style="
                                      color: #222222;
                                      white-space: normal;
                                      background-color: #ffffff;
                                      line-height: 140%;
                                    "
                                  >
                                     
                                  </p>
                                  <p
                                    style="
                                      color: #222222;
                                      white-space: normal;
                                      background-color: #ffffff;
                                      line-height: 140%;
                                    "
                                  >
                                    Detalle de envío:
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>

            <!--[if gte mso 9]>
      </v:textbox></v:rect>
    </td>
    </tr>
    </table>
    <![endif]-->

            <div
              class="u-row-container"
              style="padding: 0px; background-color: transparent"
            >
              <div
                class="u-row"
                style="
                  margin: 0 auto;
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  background-color: transparent;
                "
              >
                <div
                  style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                  "
                >
                  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                  <!--[if (mso)|(IE)]><td align="center" width="398" style="width: 398px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-66p67"
                    style="
                      max-width: 320px;
                      min-width: 400px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Nombre:</strong> ${data.sendByName}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]><td align="center" width="198" style="width: 198px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-33p33"
                    style="
                      max-width: 320px;
                      min-width: 200px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Fecha:</strong> ${data.date}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>

            <div
              class="u-row-container"
              style="padding: 0px; background-color: transparent"
            >
              <div
                class="u-row"
                style="
                  margin: 0 auto;
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  background-color: transparent;
                "
              >
                <div
                  style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                  "
                >
                  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                  <!--[if (mso)|(IE)]><td align="center" width="198" style="width: 198px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-33p33"
                    style="
                      max-width: 320px;
                      min-width: 200px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Teléfono:</strong> ${data.phone}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]><td align="center" width="198" style="width: 198px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-33p33"
                    style="
                      max-width: 320px;
                      min-width: 200px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Email:</strong> ${data.email}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]><td align="center" width="198" style="width: 198px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-33p33"
                    style="
                      max-width: 320px;
                      min-width: 200px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Destino/Medio:</strong>
                                    ${data.destination}/${
          data.type === "m" ? "Marítimo" : "Aéreo"
        }
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>

            <div
              class="u-row-container"
              style="padding: 0px; background-color: transparent"
            >
              <div
                class="u-row"
                style="
                  margin: 0 auto;
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  background-color: transparent;
                "
              >
                <div
                  style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                  "
                >
                  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                  <!--[if (mso)|(IE)]><td align="center" width="198" style="width: 198px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-33p33"
                    style="
                      max-width: 320px;
                      min-width: 200px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Total de cajas:</strong> ${
                                      data.boxsList.length
                                    }
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]><td align="center" width="398" style="width: 398px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-66p67"
                    style="
                      max-width: 320px;
                      min-width: 400px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Número de envío:</strong> ${
                                      data.shippingNumber
                                    }
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>

            <div
              class="u-row-container"
              style="padding: 0px; background-color: transparent"
            >
              <div
                class="u-row"
                style="
                  margin: 0 auto;
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  background-color: transparent;
                "
              >
                <div
                  style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                  "
                >
                  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                  <!--[if (mso)|(IE)]><td align="center" width="598" style="width: 598px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-100"
                    style="
                      max-width: 320px;
                      min-width: 600px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Dirección:</strong> ${data.address}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>

            <div
              class="u-row-container"
              style="padding: 0px; background-color: transparent"
            >
              <div
                class="u-row"
                style="
                  margin: 0 auto;
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  background-color: transparent;
                "
              >
                <div
                  style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                  "
                >
                  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                  <!--[if (mso)|(IE)]><td align="center" width="598" style="width: 598px;padding: 0px;border-top: 1px solid #CCC;border-left: 1px solid #CCC;border-right: 1px solid #CCC;border-bottom: 1px solid #CCC;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-100"
                    style="
                      max-width: 320px;
                      min-width: 600px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 1px solid #ccc;
                          border-left: 1px solid #ccc;
                          border-right: 1px solid #ccc;
                          border-bottom: 1px solid #ccc;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    <strong>Detalle de cajas:</strong>
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[0]
                                        ? data.boxsList[0].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[0] &&
                                      data?.boxsList[0]?.boxWeight
                                        ? "- " +
                                          data.boxsList[0].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[1]
                                        ? data.boxsList[1].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[1] &&
                                      data?.boxsList[1]?.boxWeight
                                        ? "- " +
                                          data.boxsList[1].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[2]
                                        ? data.boxsList[2].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[2] &&
                                      data?.boxsList[2]?.boxWeight
                                        ? "- " +
                                          data.boxsList[2].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[3]
                                        ? data.boxsList[3].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[3] &&
                                      data.boxsList[3].boxWeight
                                        ? "- " +
                                          data.boxsList[3].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[4]
                                        ? data.boxsList[4].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[4] &&
                                      data.boxsList[4].boxWeight
                                        ? "- " +
                                          data.boxsList[4].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                    </p>
                                    <p>
                                    ${
                                      data.boxsList[5]
                                        ? data.boxsList[5].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[5] &&
                                      data.boxsList[5].boxWeight
                                        ? "- " +
                                          data.boxsList[5].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                    </p>
                                    <p>
                                    ${
                                      data.boxsList[6]
                                        ? data.boxsList[6].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[6] &&
                                      data.boxsList[6].boxWeight
                                        ? "- " +
                                          data.boxsList[6].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[7]
                                        ? data.boxsList[7].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[7] &&
                                      data.boxsList[7].boxWeight
                                        ? "- " +
                                          data.boxsList[7].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[8]
                                        ? data.boxsList[8].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[8] &&
                                      data.boxsList[8].boxWeight
                                        ? "- " +
                                          data.boxsList[8].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[9]
                                        ? data.boxsList[9].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[9] &&
                                      data.boxsList[9].boxWeight
                                        ? "- " +
                                          data.boxsList[9].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[10]
                                        ? data.boxsList[10].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[10] &&
                                      data.boxsList[10].boxWeight
                                        ? "- " +
                                          data.boxsList[10].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[11]
                                        ? data.boxsList[11].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[11] &&
                                      data.boxsList[11].boxWeight
                                        ? "- " +
                                          data.boxsList[11].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[12]
                                        ? data.boxsList[12].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[12] &&
                                      data.boxsList[12].boxWeight
                                        ? "- " +
                                          data.boxsList[12].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[13]
                                        ? data.boxsList[13].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[13] &&
                                      data.boxsList[13].boxWeight
                                        ? "- " +
                                          data.boxsList[13].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                  <p>
                                    ${
                                      data.boxsList[14]
                                        ? data.boxsList[14].boxSize
                                        : ""
                                    }
                                    ${
                                      data.boxsList[14] &&
                                      data.boxsList[14].boxWeight
                                        ? "- " +
                                          data.boxsList[14].boxWeight +
                                          " " +
                                          weightUnit
                                        : ""
                                    }
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>

            <div
              class="u-row-container"
              style="padding: 0px; background-color: transparent"
            >
              <div
                class="u-row"
                style="
                  margin: 0 auto;
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  background-color: transparent;
                "
              >
                <div
                  style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                  "
                >
                  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                  <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color: #be1e2e;width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-100"
                    style="
                      max-width: 320px;
                      min-width: 600px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        background-color: #be1e2e;
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 0px solid transparent;
                          border-left: 0px solid transparent;
                          border-right: 0px solid transparent;
                          border-bottom: 0px solid transparent;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          id="u_content_text_2"
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 40px 80px 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    color: #ffffff;
                                    line-height: 160%;
                                    text-align: center;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="font-size: 14px; line-height: 160%">
                                    <span
                                      style="
                                        line-height: 22.4px;
                                        color: #ffffff;
                                      "
                                      ><span
                                        style="
                                          font-family: inherit;
                                          font-size: small;
                                          white-space: normal;
                                          float: none;
                                          display: inline;
                                          line-height: 20.8px;
                                        "
                                        >¡Ahora ya puedes revisar el estado de
                                        tu envío en</span
                                      ></span
                                    >
                                    <a
                                      rel="noopener"
                                      href="https://www.globalcargous.com"
                                      target="_blank"
                                      >www.globalcargous.com</a
                                    >
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 20px 0px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <table
                                  height="0px"
                                  align="center"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    border-collapse: collapse;
                                    table-layout: fixed;
                                    border-spacing: 0;
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    vertical-align: top;
                                    border-top: 1px solid #bbbbbb;
                                    -ms-text-size-adjust: 100%;
                                    -webkit-text-size-adjust: 100%;
                                  "
                                >
                                  <tbody>
                                    <tr style="vertical-align: top">
                                      <td
                                        style="
                                          word-break: break-word;
                                          border-collapse: collapse !important;
                                          vertical-align: top;
                                          font-size: 0px;
                                          line-height: 0px;
                                          mso-line-height-rule: exactly;
                                          -ms-text-size-adjust: 100%;
                                          -webkit-text-size-adjust: 100%;
                                        "
                                      >
                                        <span>&#160;</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div align="center">
                                  <div style="display: table; max-width: 93px">
                                    <!--[if (mso)|(IE)]><table width="93" cellpadding="0" cellspacing="0" border="0"><tr><td style="border-collapse:collapse;" align="center"><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse; mso-table-lspace: 0pt;mso-table-rspace: 0pt; width:93px;"><tr><![endif]-->

                                    <!--[if (mso)|(IE)]><td width="32" style="width:32px; padding-right: 15px;" valign="top"><![endif]-->
                                    <table
                                      align="left"
                                      border="0"
                                      cellspacing="0"
                                      cellpadding="0"
                                      width="32"
                                      height="32"
                                      style="
                                        width: 32px !important;
                                        height: 32px !important;
                                        display: inline-block;
                                        border-collapse: collapse;
                                        table-layout: fixed;
                                        border-spacing: 0;
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        vertical-align: top;
                                        margin-right: 15px;
                                      "
                                    >
                                      <tbody>
                                        <tr style="vertical-align: top">
                                          <td
                                            align="left"
                                            valign="middle"
                                            style="
                                              word-break: break-word;
                                              border-collapse: collapse !important;
                                              vertical-align: top;
                                            "
                                          >
                                            <a
                                              href="https://www.instagram.com/globalcargoutah/"
                                              title="Instagram"
                                              target="_blank"
                                            >
                                              <img
                                                src="https://download.logo.wine/logo/Instagram/Instagram-Logo.wine.png"
                                                alt="Instagram"
                                                title="Instagram"
                                                width="60"
                                                style="
                                                  outline: none;
                                                  text-decoration: none;
                                                  -ms-interpolation-mode: bicubic;
                                                  clear: both;
                                                  display: block !important;
                                                  border: none;
                                                  height: auto;
                                                  margin-top: 5px;
                                                  float: none;
                                                  max-width: 60px !important;
                                                "
                                              />
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <!--[if (mso)|(IE)]></td><![endif]-->

                                    <!--[if (mso)|(IE)]><td width="32" style="width:32px; padding-right: 0px;" valign="top"><![endif]-->
                                    <table
                                      align="left"
                                      border="0"
                                      cellspacing="0"
                                      cellpadding="0"
                                      width="32"
                                      height="32"
                                      style="
                                        width: 32px !important;
                                        height: 32px !important;
                                        display: inline-block;
                                        border-collapse: collapse;
                                        table-layout: fixed;
                                        border-spacing: 0;
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        vertical-align: top;
                                        margin-right: 0px;
                                      "
                                    >
                                      <tbody>
                                        <tr style="vertical-align: top">
                                          <td
                                            align="left"
                                            valign="middle"
                                            style="
                                              word-break: break-word;
                                              border-collapse: collapse !important;
                                              vertical-align: top;
                                            "
                                          >
                                            <a
                                              href="https://wa.me/18014996174?text=Quiero%20información%20sobre...%20"
                                              title="WhatsApp"
                                              target="_blank"
                                            >
                                              <img
                                                src="https://static.vecteezy.com/system/resources/thumbnails/024/273/818/small/whatsapp-logo-free-png.png"
                                                alt="WhatsApp"
                                                title="WhatsApp"
                                                width="50"
                                                style="
                                                  outline: none;
                                                  text-decoration: none;
                                                  -ms-interpolation-mode: bicubic;
                                                  clear: both;
                                                  display: block !important;
                                                  border: none;
                                                  height: auto;
                                                  float: none;
                                                  max-width: 50px !important;
                                                "
                                              />
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <!--[if (mso)|(IE)]></td><![endif]-->

                                    <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          style="font-family: 'Raleway', sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="v-container-padding-padding"
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 10px 10px 40px;
                                  font-family: 'Raleway', sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  class="v-font-size"
                                  style="
                                    font-size: 14px;
                                    color: #ffffff;
                                    line-height: 160%;
                                    text-align: center;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="font-size: 14px; line-height: 160%">
                                     
                                  </p>
                                  <p style="font-size: 14px; line-height: 160%">
                                     
                                  </p>
                                  <div>
                                    <p style="line-height: 160%">
                                      175 W 2700 S, South Salt Lake, UT 84115.
                                    </p>
                                    <p style="line-height: 160%">
                                      Lunes - Viernes 10 am - 4 pm
                                    </p>
                                    <p style="line-height: 160%">
                                      Sábado y Domingo - Cita previa
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>

            <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
          </td>
        </tr>
      </tbody>
    </table>
    <!--[if mso]></div><![endif]-->
    <!--[if IE]></div><![endif]-->
  </body>
</html>
`,
      },
    });
  } catch (error) {
    console.log(error);
  }
}
