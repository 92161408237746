import "./LotForm.css";

import React, { useState } from "react";

import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import InputSelect from "../../Components/Select/Select";
import Swal from "sweetalert2";
import SystemLayout from "../Layout/SystemLayout";
import { addLot } from "../../Services/firebase";

const LotForm = () => {
  const [lot, setLot] = useState("");
  const [detination, setDestination] = useState("");
  const [way, setWay] = useState("");

  const options = [
    { value: "m", label: "Marítimo" },
    { value: "a", label: "Aéreo" },
  ];

  const destino = [
    { value: "ve", label: "Venezuela" },
    { value: "co", label: "Colombia" },
    { value: "ec", label: "Ecuador" },
    { value: "pe", label: "Peru" },
    { value: "ch", label: "Chile" },
    { value: "dm", label: "Republica Dominicana" },
    { value: "mx", label: "Mexico" },
  ];

  const resetForm = () => {
    setLot("");
    setDestination(null);
    setWay(null);
  };

  const handleSubmit = () => {
    let data = {
      lotNumber: lot,
      destination: detination.value,
      type: way.value,
      date: new Date(),
    };
    addLot(data).then(() => {
      Swal.fire({
        title: "Lote creado correctamente",
        icon: "success",
      });
      resetForm();
      goBack();
    });
  };

  const goBack = () => {
    window.location.href = "/system";
  };

  const hadleDestinations = (e) => {
    setDestination(e);
    console.log(e);
  };

  const handleWay = (e) => {
    setWay(e);
    console.log(e);
  };

  return (
    <SystemLayout pageTitle="Lotes">
      <div className="form-container">
        <Button label={"Volver al menu"} onClick={goBack}></Button>
        <Input
          type="text"
          value={lot}
          onChange={(e) => setLot(e)}
          label="Numero de lote"
        />
        <InputSelect
          value={detination ? detination : null}
          onChange={hadleDestinations}
          label="Pais de Destino"
          options={destino}
        />
        <InputSelect
          value={way ? way : null}
          onChange={handleWay}
          label="Medio de transporte"
          options={options}
        />
        <Button label={"Guardar"} onClick={handleSubmit}></Button>
      </div>
    </SystemLayout>
  );
};

export default LotForm;
