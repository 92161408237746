import "./CardInfo.css";

import Button from "../Button/Button";
import React from "react";

const CardInfo = (props) => {
  const { data, onClick } = props;
  return (
    <div className="card-container col-md-4">
      <div className="header">
        <h6 className="card-title">
          {data ? data.name : ""} {data ? data.lastName : ""}
        </h6>
        <Button onClick={onClick}></Button>
      </div>
      <p className="text-card">
        <strong>Telefono: </strong>
        {data ? data.phone : ""}
      </p>
      <p className="text-card">
        <strong>Email: </strong>
        {data ? data.email : ""}
      </p>
      <h6 className="card-title2">
        {data ? data.recipients[0].name : ""}{" "}
        {data ? data.recipients[0].lastName : ""}
      </h6>
      <p className="text-card">
        <strong>Direccion: </strong>
        {data ? data.recipients[0].address : ""}
      </p>
      <p className="text-card">
        <strong>Telefono: </strong>
        {data ? data.recipients[0].phone : ""}
      </p>
    </div>
  );
};

export default CardInfo;
