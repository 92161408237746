import "./Shipping.css";

import React, { useEffect, useState } from "react";
import {
  getShipping,
  getShippingById,
  sendEmail,
  updateShipping,
} from "../../Services/firebase";

import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import Loading from "../../Components/Loading/Loading";
import ShipingForm from "../ShipingForm/ShipingForm";
import ShippingCard from "../../Components/ShippingCard/ShippingCard";
import Swal from "sweetalert2";
import SystemLayout from "../Layout/SystemLayout";
import logo from "../../assets/images/1.png";
import search from "../../assets/images/icons8-search-64.png";

const Shipping = () => {
  const [shipping, setShipping] = useState(null);
  const [dataShipping, setDataShipping] = useState([]);
  const [dataShow, setDataShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [aduana, setAduana] = useState(false);

  const handleValue = (number) => {
    setShipping(number);
    getShipping().then((res) => {
      if (!res) {
        setDataShipping(null);
      } else {
        res.forEach((shippingData) => {
          if (shippingData.data.number === number) {
            setDataShipping(shippingData);
            console.log(shippingData);
          }
        });
      }
    });
  };

  const editEmail = () => {
    Swal.fire({
      title: "Input email address",
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
    }).then((res) => {
      if (res.isConfirmed) {
        sendNewEmailData(res.value);
      }
    });
  };

  const sendEmailData = () => {
    setShowEdit(false);
    sendEmail({
      to: dataShipping.data.email,
      subject: "Envío registrado",
      address: dataShipping.data.address,
      nameSend: dataShipping.data.sendBy,
      shippingNumber: dataShipping.data.number,
      nameRecive: dataShipping.data.sendTo,
      phone: dataShipping.data.phone,
      email: dataShipping.data.email,
      boxsList: dataShipping.data.boxList,
      date: dataShipping.data.date,
      type: dataShipping.data.type,
    });
  };

  const sendNewEmailData = (newEmail) => {
    setShowEdit(false);
    sendEmail({
      to: newEmail,
      subject: "Envío registrado",
      address: dataShipping.data.address,
      nameSend: dataShipping.data.sendBy,
      shippingNumber: dataShipping.data.number,
      nameRecive: dataShipping.data.sendTo,
      phone: dataShipping.data.phone,
      email: newEmail,
      boxsList: dataShipping.data.boxList,
      date: dataShipping.data.date,
      type: dataShipping.data.type,
    });
  };

  const updateShippingData = () => {
    let data = {
      lotNumber: dataShipping.data.lot,
      shipingNumber: dataShipping.data.number,
      name: dataShipping.data.sendBy,
      lastName: dataShipping.data.lastName,
      email: dataShipping.data.email,
      phone: dataShipping.data.phone,
      nameRecibe: dataShipping.data.sendTo,
      lastNameRecibe: dataShipping.data.lastNameRecibe,
      phoneRecibe: dataShipping.data.phoneRecibe,
      address: dataShipping.data.address,
      status: dataShipping.data.status,
      boxList: dataShipping.data.boxList,
      date: new Date(),
    };
  };

  return (
    <SystemLayout pageTitle="Consulta de envio">
      <div className="container-shipping">
        <Input
          label="Consulta de envio"
          type="text"
          value={shipping}
          onChange={handleValue}
        />
        {shipping && dataShipping.data ? (
          <ShippingCard
            data={dataShipping.data}
            aduana={aduana}
            setAduana={setAduana}
          />
        ) : (
          <h4>No se encontro ningun envio</h4>
        )}
        <div className="buttons-container">
          <Button label="Enviar email" onClick={sendEmailData} />
          {/* <Button label="Actualizar Envio" onClick={updateShippingData} /> */}
          <Button
            label="Actualizar email"
            onClick={editEmail}
            disabled={!dataShipping.data}
          />
        </div>
      </div>
    </SystemLayout>
  );
};

export default Shipping;
