import "./ShippingCard.css";

import React, { useEffect, useState } from "react";

import logo from "../../assets/images/1.png";

const ShippingCard = (props) => {
  const { data, setAduana } = props;
  const [status, setStatus] = useState("");
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
    setAduana(!checked);
  };

  useEffect(() => {
    setStatus(data ? data.status : null);
    setChecked(data ? data.aduanaPay : false);
  }, [data]);

  return (
    <div className="card-container-shipping">
      <img className="logo-card" src={logo} alt="" />
      <p>
        <strong>Lote: </strong>
        {data ? data.lot : ""}
      </p>
      <p>
        <strong>Envio N: </strong>
        {data ? data.number : ""}
      </p>
      <p>
        <strong>Status: </strong>
        {status == "1"
          ? "Recibido en Almacen"
          : status == "2"
          ? "En transito USA"
          : status == "3"
          ? "En transito Maritimo/Aereo"
          : status == "4"
          ? "En aduana"
          : status == "5"
          ? "En transito a destino"
          : status == "6"
          ? "Entregado"
          : ""}
      </p>
      <div className="customer-data">
        <p>
          <strong>Envia: </strong>
          {data ? data.sendBy : ""}
        </p>
        <p>
          <strong>Recibe: </strong>
          {data ? data.sendTo : ""}
        </p>
      </div>
      <p>
        <strong>Email: </strong>
        {data ? data.email : ""}
      </p>
      <p>
        <strong>Telefono: </strong>
        {data ? data.phone : ""}
      </p>
      <p>
        <strong>Direccion: </strong>
        {data ? data.address : ""}
      </p>
      {/* <label>
        <input type="checkbox" checked={checked} onChange={handleChange} />
        {` Retenido en Aduana por falta de pago`}
      </label> */}
    </div>
  );
};

export default ShippingCard;
