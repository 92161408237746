import { Link, Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import App from "../App";
import CustumerRegister from "../Pages/CustumerRegister/CustumerRegister";
import Home from "../Pages/Home/Home";
import Login from "../Pages/Login/Login";
import LotForm from "../Pages/LotForm/LotForm";
import LotMenu from "../Pages/LotMenu/LotMenu";
import LotUpdate from "../Pages/LotUpdate/LotUpdate";
import Menu from "../Pages/Menu/Menu";
import SearchScreen from "../Pages/Search/SearchScreen";
import ShipingForm from "../Pages/ShipingForm/ShipingForm";
import ShipingMenu from "../Pages/ShippinMenu/ShippinMenu";
import Shipping from "../Pages/Shipping/Shipping";
import { useUser } from "../Context/UserContext";

const RoutesApp = () => {
  const { isLoggued } = useUser();

  useEffect(() => {}, [isLoggued]);

  return (
    <Routes>
      <Route path="/system" element={!isLoggued ? <Login /> : <Menu />} />
      <Route path="/system/login" element={<Login />} />
      <Route path="/tracking" element={<SearchScreen />} />
      <Route path="/" element={<Home />} />
      <Route
        path="/system/lot-form"
        element={!isLoggued ? <Login /> : <LotForm />}
      />
      <Route
        path="/system/lot"
        element={!isLoggued ? <Login /> : <LotMenu />}
      />
      <Route
        path="/system/lot-update"
        element={!isLoggued ? <Login /> : <LotUpdate />}
      />
      <Route
        path="/system/shipping-form"
        element={!isLoggued ? <Login /> : <ShipingForm />}
      />
      <Route
        path="/system/shipping-finder"
        element={!isLoggued ? <Login /> : <Shipping />}
      />
      <Route
        path="/system/shipping"
        element={!isLoggued ? <Login /> : <ShipingMenu />}
      />
      <Route
        path="/system/customer-register"
        element={!isLoggued ? <Login /> : <CustumerRegister />}
      />
      <Route
        path="/system/shipping-consult"
        element={!isLoggued ? <Login /> : <Shipping />}
      />
    </Routes>
  );
};

export default RoutesApp;
