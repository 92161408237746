import React, { useEffect, useMemo, useState } from "react";

const UserContext = React.createContext();

export function UserProvider(props) {
  const [user, setUser] = useState(null);
  const [isLoggued, seIsLogged] = useState(false);

  const validateLogin = () => {
    let boolString = "true";
    let res = boolString === localStorage.getItem("isLogged");
    if (res) {
      seIsLogged(res);
    } else {
      seIsLogged(res);
    }
  };

  useEffect(() => {
    validateLogin();
  }, [localStorage.getItem("isLogged")]);

  const value = useMemo(() => {
    return {
      user,
      validateLogin,
      isLoggued,
    };
  }, [user, isLoggued]);

  return <UserContext.Provider value={value} {...props} />;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("UseUser must be inside the user provider");
  }

  return context;
}
