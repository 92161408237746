import "./Menu.css";

import Button from "../../Components/Button/Button";
import ButtonMenu from "../../Components/ButtonMenu/ButtonMenu";
import React from "react";
import SystemLayout from "../Layout/SystemLayout";
import logo from "../../assets/images/1.png";
import { userLogOut } from "../../Services/firebase";

const Menu = () => {
  const buttons = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  };
  const buttonStyles = {
    width: "100px",
    height: "100px",
    backgroundColor: "#BE1C2D",
    borderRadius: "30px",
    color: "white",
    margin: "20px",
  };

  const logout = () => {
    userLogOut();
    window.location.href = "/system/login";
  };

  return (
    <SystemLayout pageTitle="Menu">
      <div className="logout">
        <Button label={"Logout"} onClick={logout}></Button>
      </div>
      <div style={buttons}>
        <ButtonMenu label="Lotes" icon="lot" link="/system/lot" />
        <ButtonMenu label="Envios" icon="shipping" link="/system/shipping" />
        <ButtonMenu
          label="Clientes"
          icon="user"
          link="/system/customer-register"
        />
      </div>
    </SystemLayout>
  );
};

export default Menu;
