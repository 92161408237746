import React from "react";
import RoutesApp from "./Routes/Routes";

function App() {
  return (
    <RoutesApp>
      <div className="App"></div>
    </RoutesApp>
  );
}

export default App;
