import {
  FaClock,
  FaCubes,
  FaFacebookF,
  FaInstagram,
  FaMailBulk,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaRulerCombined,
  FaShip,
  FaStar,
  FaUsers,
  FaWhatsapp,
} from "react-icons/fa";

import React from "react";
import bg1 from "./img/bg-1.jpg";
import bg2 from "./img/bg-2.jpg";
import boxs from "./img/boxs.png";
import delivery from "./img/delivery.png";
import dep from "./img/dep.jpeg";
import envAer from "./img/env-aer.jpeg";
import envMar from "./img/env-mar.jpeg";
import envTer from "./img/env-ter.jpeg";
import logoWhite from "./img/logo-white.png";
import multilikes from "../../assets/sponsors/Web Banner2.jpg";
import multilikesMobile from "../../assets/sponsors/Web Banner.jpg";
import nac from "./img/nac.jpeg";

const Home = () => {
  return (
    <>
      <div className="text-center" style={{ backgroundColor: "black" }}>
        Publicidad
      </div>
      <div className="banner-top">
        <img className="img" src={multilikes} alt="" />
        <img className="img-mobile" src={multilikesMobile} alt="" />
      </div>
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-primary sticky-top p-0">
        <a
          href="index.html"
          className="navbar-brand bg-primary d-flex align-items-center px-4 px-lg-5"
        >
          <img style={{ width: "200px" }} src={logoWhite} alt="" />
        </a>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <a href="/" className="nav-item nav-link active">
              Home
            </a>
            <a href="#about" className="nav-item nav-link">
              Nosotros
            </a>
            <a href="#services" className="nav-item nav-link">
              Servicios
            </a>
            <a href="#price" className="nav-item nav-link">
              Precios
            </a>
            <a href="/tracking" className="nav-item nav-link">
              Tracking
            </a>
            <a href="#" className="nav-item nav-link">
              Contactanos
            </a>
          </div>
          <h4 className="m-0 pe-lg-5 d-none d-lg-block">
            <a target="_blank" href="https://wa.me/18014996174">
              <FaWhatsapp />
              +1 (801) 499-6174
            </a>
          </h4>
        </div>
      </nav>
      <div className="container-fluid p-0 pb-5">
        <img className="img-fluid" src={bg1} alt="" />
        <div className="owl-carousel header-carousel position-relative mb-5">
          <div className="owl-carousel-item position-relative">
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(6, 3, 21, .5)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-10 col-lg-8">
                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                      Transport & Logistics Solution
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      #1 Place For Your{" "}
                      <span className="text-primary">Logistics</span> Solution
                    </h1>
                    <p className="fs-5 fw-medium text-white mb-4 pb-2">
                      Vero elitr justo clita lorem. Ipsum dolor at sed stet sit
                      diam no. Kasd rebum ipsum et diam justo clita et kasd
                      rebum sea elitr.
                    </p>
                    <a
                      href=""
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                    <a
                      href=""
                      className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                    >
                      Free Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="owl-carousel-item position-relative">
            <img className="img-fluid" src={bg2} alt="" />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(6, 3, 21, .5)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-10 col-lg-8">
                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                      Transport & Logistics Solution
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      #1 Place For Your{" "}
                      <span className="text-primary">Transport</span> Solution
                    </h1>
                    <p className="fs-5 fw-medium text-white mb-4 pb-2">
                      Vero elitr justo clita lorem. Ipsum dolor at sed stet sit
                      diam no. Kasd rebum ipsum et diam justo clita et kasd
                      rebum sea elitr.
                    </p>
                    <a
                      href=""
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                    <a
                      href=""
                      className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                    >
                      Free Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="container-fluid overflow-hidden py-5 px-lg-0">
        <div className="container about py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0 wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src={delivery}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h6 className="text-secondary text-uppercase mb-3">Nosotros</h6>
              <h1 className="mb-5">Global Cargo Utah</h1>
              <p className="mb-5">
                Bienvenidos a Global Cargo Utah, su socio confiable en
                soluciones logísticas para envíos desde los Estados Unidos hacia
                Venezuela, Colombia, Ecuador y más países de Sudamérica. Con más
                de 5 años de experiencia en la industria, nos enorgullece
                ofrecer servicios de envío confiables y eficientes para
                satisfacer las necesidades únicas de nuestros clientes. Desde
                paquetes pequeños hasta cargas pesadas, nuestro equipo dedicado
                está comprometido a brindarle una experiencia sin contratiempos,
                asegurando la entrega oportuna y segura de sus envíos. En Global
                Cargo Utah, nuestra misión es superar sus expectativas y
                facilitar el comercio internacional con profesionalismo y
                excelencia en cada paso del camino. Confíe en nosotros para
                manejar sus envíos con cuidado y precisión, mientras llevamos
                sus productos a su destino final de manera rápida y confiable.
              </p>
              <div className="row g-4 mb-5">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                  <FaMapMarkedAlt />
                  <h5>Nuevas Rutas</h5>
                  <p className="m-0">
                    Llegamos a muchos mas paises de Suramerica
                  </p>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                  <FaClock />

                  <h5>Envios a tiempo</h5>
                  <p className="m-0">
                    Trabajamos en llevar tus envios a destino y a tiempo
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-secondary text-uppercase mb-3">
                Algunos Logros{" "}
              </h6>
              <h1 className="mb-5">Trabajamos mes a mes ser los mejores</h1>
              <p className="mb-5">
                Nuestra misión es superar sus expectativas y facilitar el
                comercio internacional con profesionalismo y excelencia en cada
                paso del camino. Confíe en nosotros para manejar sus envíos con
                cuidado y precisión, mientras llevamos sus productos a su
                destino final de manera rápida y confiable.
              </p>
              <div className="d-flex align-items-center">
                <div className=" bg-primary p-3 text-white">
                  <FaWhatsapp style={{ fontSize: "2rem" }} />
                </div>
                <div className="ps-4">
                  <h6>Llamanos para mas informacion</h6>
                  <h3 className="text-primary m-0">+1 (801) 499-6174</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-4 align-items-center">
                <div className="col-sm-6">
                  <div
                    className="bg-primary p-4 mb-4 wow fadeIn"
                    data-wow-delay="0.3s"
                  >
                    <div style={{ fontSize: "2rem", color: "#ffffff" }}>
                      <FaUsers />
                    </div>
                    <h2 className="text-white mb-2" data-toggle="counter-up">
                      +5000
                    </h2>
                    <p className="text-white mb-0">Clientes Felices</p>
                  </div>
                  <div
                    className="bg-secondary p-4 wow fadeIn"
                    data-wow-delay="0.5s"
                  >
                    <div style={{ fontSize: "2rem", color: "#ffffff" }}>
                      <FaCubes />
                    </div>
                    <h2 className="text-white mb-2" data-toggle="counter-up">
                      +5000
                    </h2>
                    <p className="text-white mb-0">Envios Completados</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="bg-success p-4 wow fadeIn"
                    data-wow-delay="0.7s"
                  >
                    <div style={{ fontSize: "2rem", color: "#ffffff" }}>
                      <FaStar />
                    </div>
                    <h2 className="text-white mb-2" data-toggle="counter-up">
                      3000
                    </h2>
                    <p className="text-white mb-0">Referencias de clientes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="container-xxl py-5">
        <div className="container py-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="text-secondary text-uppercase">
              Nuestros Servicios
            </h6>
            <h1 className="mb-5">Revisa nuestros Servicios</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={envAer} alt="" />
                </div>
                <h4 className="mb-3">Envios Aereos</h4>
                <p>
                  En Global Cargo Utah, comprendemos la importancia de la
                  velocidad y la eficiencia cuando se trata de envíos aéreos.
                  Nuestro equipo altamente capacitado y experimentado se encarga
                  de coordinar cada detalle, desde la recogida hasta la entrega,
                  para garantizar que sus productos lleguen a su destino final
                  de manera rápida y segura. Trabajamos en estrecha colaboración
                  con una red de aerolíneas confiables para ofrecerle opciones
                  flexibles y costos competitivos, asegurando que sus envíos
                  lleguen a tiempo, sin importar lo lejos que sea su destino.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={envMar} alt="" />
                </div>
                <h4 className="mb-3">Envios Maritimos</h4>
                <p>
                  Con Global Cargo Utah, puede confiar en nosotros para manejar
                  sus envíos marítimos con la máxima profesionalidad y cuidado.
                  Nuestro equipo experto se encarga de coordinar cada aspecto de
                  su envío, desde la planificación de la ruta hasta la gestión
                  de la documentación aduanera, para garantizar una experiencia
                  sin problemas en cada paso del camino. Colaboramos con las
                  principales compañías navieras para ofrecerle tarifas
                  competitivas y opciones de tránsito flexibles, asegurando que
                  sus productos lleguen a su destino de manera segura y dentro
                  del plazo acordado.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={envTer} alt="" />
                </div>
                <h4 className="mb-3">Envios Terrestres</h4>
                <p>
                  En Global Cargo Utah, comprendemos la importancia de contar
                  con una red de transporte terrestre confiable y eficiente. Ya
                  sea que necesite transporte de carga completa o parcial,
                  nuestro equipo se encarga de coordinar cada aspecto de su
                  envío, desde la recogida hasta la entrega, para garantizar que
                  sus productos lleguen a su destino final de manera oportuna y
                  segura. Trabajamos en estrecha colaboración con una red de
                  transportistas de confianza para ofrecerle soluciones
                  personalizadas que se adapten a sus necesidades específicas.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={nac} alt="" />
                </div>
                <h4 className="mb-3">Nacionalizacion</h4>
                <p>
                  Facilitamos el proceso de nacionalización de mercancía para
                  nuestros clientes, asegurando que sus productos cumplan con
                  todas las regulaciones y requisitos locales antes de su
                  entrega. Nuestro equipo experto se encarga de manejar toda la
                  documentación aduanera y coordinar con las autoridades
                  pertinentes para garantizar una experiencia sin problemas en
                  cada paso del proceso. Ya sea que esté importando productos a
                  pequeña escala o a gran escala, puede confiar en nosotros para
                  asegurar una nacionalización rápida y eficiente de su
                  mercancía.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={dep} alt="" />
                </div>
                <h4 className="mb-3">Deposito en Miami</h4>
                <p>
                  Nuestro servicio de depósito en Miami ofrece una solución
                  conveniente y segura para almacenar sus productos antes de su
                  envío. Con instalaciones modernas y seguras, puede confiar en
                  nosotros para manejar sus necesidades de almacenamiento con la
                  máxima atención y cuidado. Ya sea que necesite almacenamiento
                  a corto plazo o a largo plazo, nuestro equipo se encarga de
                  manejar cada aspecto de su mercancía, desde la recepción hasta
                  el embalaje, para garantizar su seguridad y protección
                  mientras está en nuestro depósito.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="price" className="container-xxl py-5">
        <div className="container py-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="text-secondary text-uppercase">Nuestros Precios</h6>
            <h1 className="mb-5">Los mejores del mercado</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="price-item">
                <div className="border-bottom p-4 mb-4">
                  <h5 className="text-primary mb-1">Caja Small</h5>
                  <h1 className="display-5 mb-0">
                    <small
                      className="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    100.00
                  </h1>
                  <img style={{ width: "200px" }} src={boxs} alt="" />
                </div>
                <div className="p-4 pt-0">
                  <p>
                    <FaRulerCombined />
                    Medidas:
                  </p>
                  <p>17X11X11 / 12X12X16</p>
                  <p>Precios de envios maritimos de Utah a Venezuela</p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="price-item">
                <div className="border-bottom p-4 mb-4">
                  <h5 className="text-primary mb-1">Caja Medium</h5>
                  <h1 className="display-5 mb-0">
                    <small
                      className="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    160.00
                  </h1>
                  <img style={{ width: "200px" }} src={boxs} alt="" />
                </div>
                <div className="p-4 pt-0">
                  <p>
                    <FaRulerCombined />
                    Medidas:
                  </p>
                  <p>21X15X16 / 18X18X16</p>
                  <p>Precios de envios maritimos de Utah a Venezuela</p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="price-item">
                <div className="border-bottom p-4 mb-4">
                  <h5 className="text-primary mb-1">Caja Large</h5>
                  <h1 className="display-5 mb-0">
                    <small
                      className="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    210.00
                  </h1>
                  <img style={{ width: "200px" }} src={boxs} alt="" />
                </div>
                <div className="p-4 pt-0">
                  <p>
                    <FaRulerCombined />
                    Medidas:
                  </p>
                  <p>27X15X16 / 18X18X24</p>
                  <p>Precios de envios maritimos de Utah a Venezuela</p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="price-item">
                <div className="border-bottom p-4 mb-4">
                  <h5 className="text-primary mb-1">Caja Extra Large</h5>
                  <h1 className="display-5 mb-0">
                    <small
                      className="align-top"
                      style={{ fontSize: "22px", lineHeight: "45px" }}
                    >
                      $
                    </small>
                    280.00
                  </h1>
                  <img style={{ width: "200px" }} src={boxs} alt="" />
                </div>
                <div className="p-4 pt-0">
                  <p>
                    <FaRulerCombined />
                    Medidas:
                  </p>
                  <p>24X20X21 / 22X22X21</p>
                  <p>Precios de envios maritimos de Utah a Venezuela</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container-fluid bg-dark text-light footer pt-5 wow fadeIn"
        data-wow-delay="0.1s"
        style={{ marginTop: "6rem" }}
      >
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Ubicacion</h4>
              <p class="mb-2">
                <FaMapMarkerAlt />
                175 W. 2700 S. south salt lake, Ut, 84115
              </p>
              <p class="mb-2">
                <FaWhatsapp />
                +1 (801) 499-6174
              </p>
              <p class="mb-2">
                <FaMailBulk />
                info@globalcargous.com
              </p>
              <div class="d-flex pt-2">
                <a
                  target="_blank"
                  class="btn btn-outline-light btn-social"
                  href="https://www.instagram.com/globalcargoutah/"
                >
                  <FaInstagram />
                </a>
                <a
                  target="_blank"
                  class="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/profile.php?id=100063061069991&paipv=0&eav=AfaLowZmlH1SOQacLWMKUd2xAjBFmUhH2HAw6KHQGa1MhQSabe1JfZEXCNFks97YLmE"
                >
                  <FaFacebookF />
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Servicios</h4>
              <div>Deposito</div>

              <div>Envios Maritimos</div>

              <div>Envios Aereos</div>

              <div>Envios terrestres</div>

              <div>Nacionalizacion</div>
            </div>
            <div class="col-lg-3 col-md-6">
              <h4 class="text-light mb-4">Secciones</h4>
              <a class="btn btn-link" href="#about">
                Nosotros
              </a>
              <a class="btn btn-link" href="#services">
                Servicios
              </a>
              <a class="btn btn-link" href="#price">
                Precios
              </a>
            </div>
            <div class="col-lg-3 col-md-6">
              <img style={{ width: "300px" }} src={logoWhite} alt="" />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="copyright">
            <div class="row">
              <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy;{" "}
                <a class="border-bottom" href="#">
                  Global Cargo Utah
                </a>
                , All Right Reserved.
              </div>
              <div class="col-md-6 text-center text-md-end">
                Designed By{" "}
                <a
                  target="_blank"
                  class="border-bottom"
                  href="https://www.linkedin.com/in/gian-machella-1b9a90187/"
                >
                  Gian Machella
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        href="#"
        class="btn btn-lg btn-primary btn-lg-square rounded-0 back-to-top"
      >
        <i class="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default Home;
