import "./ButtonMenu.css";

import React from "react";
import lot from "../../assets/images/lot.png";
import shipping from "../../assets/images/form.png";
import user from "../../assets/images/user.png";

const ButtonMenu = (props) => {
  const { link, label, icon } = props;

  return (
    <button className="button">
      <a className="link" href={link}>
        <img
          className="icon"
          src={
            icon === "lot"
              ? lot
              : icon === "shipping"
              ? shipping
              : icon === "user"
              ? user
              : ""
          }
          alt=""
        />
        <p>{label}</p>
      </a>
    </button>
  );
};

export default ButtonMenu;
