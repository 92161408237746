import "./SystemLayout.css";

import Button from "../../Components/Button/Button";
import React from "react";
import logo from "../../assets/images/logo-system.png";

const SystemLayout = (props) => {
  const { pageTitle, children, labelButton, onClick, link } = props;

  return (
    <div className="container">
      <img src={logo} alt="" className="logo" />
      <h4>{pageTitle}</h4>
      <div>{children}</div>
    </div>
  );
};

export default SystemLayout;
