import React from "react";

const getAuthToken = async () => {
  const myInit = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "api-token":
        "SZ6IXyRUxVfdtuC-zyedoXLLrSMT1X0p8SKtyXt9m5a9TqmCBOP-0Sgs4hdFmtq9e-Q",
      "user-email": "gianmachellaf@gmail.com",
    },
  };

  const myRequest = new Request(
    "https://www.universal-tutorial.com/api/getaccesstoken",
    myInit
  );

  return await fetch(myRequest, myInit).then((response) => response.json());
};

const getCountries = async (token) => {
  const myInit = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const myRequest = new Request(
    "https://www.universal-tutorial.com/api/countries/",
    myInit
  );
  return await fetch(myRequest, myInit).then((response) => response.json());
};

const getStates = async (token, country) => {
  const myInit = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const myRequest = new Request(
    `https://www.universal-tutorial.com/api/states/${country}`,
    myInit
  );
  return await fetch(myRequest, myInit).then((response) => response.json());
};

const getCities = async (token, state) => {
  const myInit = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const myRequest = new Request(
    `https://www.universal-tutorial.com/api/cities/${state}`,
    myInit
  );
  return await fetch(myRequest, myInit).then((response) => response.json());
};

export { getAuthToken, getCountries, getStates, getCities };
